import React from 'react'
import HomepageComponent from '../components/homepage/homepage'

class HomePage extends React.Component {
  render() {
    return <HomepageComponent></HomepageComponent>
  }
}

export default HomePage
